<!-- 
	This is the user profile page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div>

		<!-- Header Background Image -->
		<div class="profile-nav-bg" style="background-image: url('images/bg-profile.jpg')"></div>
		<!-- / Header Background Image -->

		<!-- User Profile Card -->
		<a-card :bordered="false" class="card-profile-head" :bodyStyle="{ padding: 0, }">
			<template #title>
				<a-row type="flex" align="middle">
					<a-col :span="24" :md="12" class="col-info">
						<a-avatar :size="74" shape="square" src="images/face-0.svg" />
						<div class="avatar-info">
							<h4 class="font-semibold m-0">{{ username }}</h4>
							<p>Администратор</p>
						</div>
					</a-col>
				</a-row>
			</template>
		</a-card>
		<a-card :bordered="false" class="header-solid h-full mb-24" :bodyStyle="{ paddingTop: '14px' }">
			<template #title>
				<h6 class="font-semibold">Заявки на авторизацию</h6>
				<p>Заявки на авторизацию учетных записей администратора</p>
			</template>
			<a-row type="flex" :gutter="[24, 24]" align="stretch">
				<a-col :span="24" class="mb-24">
					<a-table :columns="tAdminsColumns" :data-source="tAdminsDataSource" rowKey="ID">
						<template slot="editBtn" slot-scope="row">
							<a-checkbox :disabled=disabledHack(row) :checked=checkboxHack(row)
								@change="onCheckboxChange(row)"></a-checkbox>
						</template>
						<template slot="createdAt" slot-scope="createdAt">
							<p>
								{{ new Date(createdAt).toLocaleDateString() }}
							</p>
						</template>
					</a-table>
				</a-col>
			</a-row>
		</a-card>
		<a-row type="flex" :gutter="[24, 24]" align="stretch">
			<a-col :span="18" class="mb-24">
				<!-- Regular users card -->
				<CardUserList :data="conversationsData" @remove="remove"></CardUserList>
			</a-col>
			<!--
			<a-col :span="24" :md="12" class="mb-24">
				<CardAdminList :data="administratorsData"></CardAdminList>
			</a-col> -->
		</a-row>
	</div>
</template>

<script>

/* import CardConversations from "../components/Cards/CardConversations";
import CardPlatformSettings from "../components/Cards/CardPlatformSettings";
import CardProfileInformation from "../components/Cards/CardProfileInformation"; */
import { notification } from "ant-design-vue";
import axios from "axios";
import CardAdminList from "../components/Cards/CardAdminList.vue";
import CardProject from "../components/Cards/CardProject";
import CardUserList from "../components/Cards/CardUserList";

// Conversation's list data.
const conversationsData = [
	/*
	{
		id: "1",
		title: "Sophie B.",
		code: "Hi! I need more information…",
		avatar: "images/face-3.jpg",
	},
	{
		id: "2",
		title: "Anne Marie",
		code: "Awesome work, can you…",
		avatar: "images/face-4.jpg",
	},
	{
		id: "3",
		title: "Ivan",
		code: "About files I can…",
		avatar: "images/face-5.jpeg",
	},
	{
		id: "4",
		title: "Peterson",
		code: "Have a great afternoon…",
		avatar: "images/face-6.jpeg",
	},
	{
		id: "5",
		title: "Nick Daniel",
		code: "Hi! I need more information…",
		avatar: "images/face-2.jpg",
	},
	*/
];

export default ({
	components: {
		/* 		CardPlatformSettings,
				CardProfileInformation,
				CardConversations, */
		CardUserList,
		CardAdminList,
		CardProject,
	},
	methods: {
		remove(i) {
			this.conversationsData.splice(i, 1);
		},
		openNotification(placement, status, message) {
			const api = notification.open;
			api({
				message: status,
				description: message,
				placement
			});
		},
		async handleSubmit(row) {
			try {
				let prequest = {
					AdminId: row.ID,
					IsAuthorized: row.IsAuthorized
				};
				const response = await axios.post("api/v1/authadmin", prequest);
				console.log(response.data);
				return true;
			}
			catch (error) {
				console.log(error);
				return false;
			}
		},
		checkboxHack(row) {
			return row.IsAuthorized == 1;
		},
		disabledHack(row) {
			return row.ID === 1;
		},
		onCheckboxChange(row) {
			if (row.ID === 1) {
				return;
			}
			let backup = row.IsAuthorized;
			const flavorText = [
				"🔒 Успешно сняли авторизацию у администратора ",
				"🔑 Успешно авторизовали администратора ",
			]
			if (row.IsAuthorized == 1) {
				row.IsAuthorized = 0;
			}
			else {
				row.IsAuthorized = 1;
			}
			if (this.handleSubmit(row) === false) {
				row.IsAuthorized = backup;
				this.openNotification('topRight', "Произошла ошибка в ходе обновления данных пользователя " + row.Name);
				return;
			}
			this.openNotification('topRight', flavorText[row.IsAuthorized] + row.Name);
		},
		fetchData() {
			axios
				.get("api/v1/newusers")
				.then((response) => {
					let tempUsersTableData = new Array();
					response.data.forEach(element => {
						console.log(element);
						tempUsersTableData.push(
							{
								id: element.ID,
								title: element.FullName,
								code: this.groups.get(element.GroupIdx),
								avatar: "images/face-user.png",
							}
						);
					});
					this.conversationsData = tempUsersTableData;

				})
				.catch((err) => {
					console.log(err);
				});
			axios
				.get("api/v1/adminlist")
				.then((response) => {
					console.log(response.data);
					this.tAdminsDataSource = response.data;
				})
				.catch((err) => {
					console.log(err);
				});
		}
	},
	created() {
		this.fetchData();
	},
	data() {
		return {
			// Active button for the "User Profile" card's radio button group.
			//profileHeaderBtns: 'overview',
			value: '',
			submitting: false,

			// Associating Conversation's list data with its corresponding property.
			conversationsData,

			tAdminsColumns: [
				{
					name: "name",
					dataIndex: "Name",
					key: "aname",
					title: "Имя",
				},
				{
					name: "email",
					dataIndex: "Email",
					key: "aemail",
					title: "Email",
				},
				{
					title: "Авторизован?",
					scopedSlots: {
						customRender: "editBtn"
					},
				},
				{
					name: "date",
					dataIndex: "CreatedAt",
					title: "Дата создания",
					scopedSlots: {
						customRender: "createdAt",
					},
					key: "adate",
				}
			],
			tAdminsDataSource: null,

			//username: localStorage.getItem("loggedUsername")
			username: "administrator@example.com",
			groups: new Map([
				[1, 'Системный аналитик'],
				[2, 'Таможня'],
				[3, 'Большие данные'],
				[4, 'Машинное обучение'],
				[5, 'Системный аналитик (гостеприимство)'],
				[6, 'Не обучается на цифровой кафедре'],
			]),
		}
	},
})

</script>

<style lang="scss">
</style>
