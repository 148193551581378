<template>
	<!-- Conversations Card -->
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ paddingTop: 0, paddingBottom: '16px' }">
		<template #title>
			<h6 class="font-semibold m-0">Новые пользователи</h6>
		</template>
		<a-list class="conversations-list" item-layout="horizontal" :split="false" :data-source="data">
			<a-list-item slot="renderItem" slot-scope="item">
				<a-button slot="actions" type="link" @click="showModal">
					СООБЩЕНИЕ
				</a-button>
				<a-modal v-model:visible="visible" title="Отправить сообщение" :confirm-loading="confirmLoading"
					@ok="modalHandleOk(item)">
					<p> Введите сообщение: </p>
					<a-form :model="form" @submit="modalHandleSubmit">
						<a-form-item label="msg to user">
							<a-input v-model:value="form.field" />
						</a-form-item>
					</a-form>
				</a-modal>
				<a-list-item-meta :title="item.title" :description="item.code">
					<a-avatar slot="avatar" :size="48" shape="square" :src="item.avatar" />
				</a-list-item-meta>
				<a-popconfirm
					title="Вы действительно хотите удалить этого пользователя? Эта операция удалит вопросы от этого пользователя"
					ok-text="Удалить" cancel-text="Отмена" @confirm="delPopConfirm(item)" @cancel="delPopCancel()">
					<a class="text-secondary">УДАЛИТЬ</a>
				</a-popconfirm>
			</a-list-item>
		</a-list>
	</a-card>
	<!-- / Conversations Card -->
</template>

<script>

import { Form, Input, Modal, Popconfirm, message } from "ant-design-vue";
import axios from "axios";

export default ({
	components: {
		'a-popconfirm': Popconfirm,
		[Modal.name]: Modal,
		[Form.name]: Form,
		[Form.Item.name]: Form.Item,
		[Input.name]: Input,

	},
	props: {
		data: {
			type: Array,
			default: () => [],
		},
	},
	methods: {
		delPopConfirm(item) {
			axios
				.get("/api/v1/deluser", { params: { id: item.id } })
				.then((response) => {
					message.success('Удалили пользователя ' + item.Name);
					$emit('remove', item);
				})
				.catch((error) => {
					console.log(error.code);
					message.error('Произошла ошибка при удалении пользователя. Код ошибки: ' + error.code);
				});
		},
		delPopCancel() {
		},
		showModal() {
			this.visible = true;
		},
		modalHandleOk(item) {
			console.log(item);
			this.confirmLoading = true;
			// TODO handle submission
			let presquest = {
				MessageText: this.form.field,
				UserId: item.id,
			};
			console.log(presquest);
			axios
				.post("api/v1/admin/sendmsg", presquest)
				.then((response) => {
					message.success("Отправили сообщение пользователю");
				})
				.catch((error) => {
					message.error("Сбой отправки сообщения пользователю " + error.code);
				});
			this.confirmLoading = false;
			this.visible = false;
		},
		modalHandleSubmit(event) {
			event.preventDefault();
		},
	},
	data() {
		return {
			visible: false,
			confirmLoading: false,
			form: {
				field: '',
			},
		};
	},
})

</script>
